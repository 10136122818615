import React, { Component } from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron'

import './AboutMe.scss'

class AboutMe extends Component {
    render() {
        return (
            <div className='about-me d-flex flex-column align-items-center'>
                <h1 className='mt-5 mb-4 text-tint'>Über mich</h1>
                <div className='profile mb-5 shadow-lg' />
                <Jumbotron className='pt-0 mt-4 mb-0'>
                    <div className='text-container text-left'>
                        <p>
                            Ich kann nicht sagen, wann ich das erste Mal einen Stift in die Finger bekommen habe. Ich weiß nur, seit ich denken kann, bin ich vom Zeichnen fasziniert. 
                            Stundenlang konnte ich mich mit meinen Werken beschäftigen. Ob es nun Buntstifte waren, Bleistifte oder Kohle, Aquarell, Acryl, Wachsmalkreide – alles war spannend für mich. Wenn ich zeichnen konnte, war ich einfach glücklich.
                        </p>
                        <p>
                            Und dennoch. Obwohl das Zeichnen für mich so natürlich war – je älter ich wurde, desto weniger zeichnete ich. Schule, Studium und später die Arbeit nahmen viel Zeit ein und auch ich erfuhr, was es bedeutet gestresst zu sein. Jahrelang zeichnete ich nur sehr wenig.
                        </p>
                        <p>
                            2018 geschah jedoch etwas, dass das ändern sollte. Mein Neffe wurde geboren. Für ihn wollte ich endlich wieder die Stifte auspacken, um ein paar Zeichnungen für sein Zimmer anzufertigen. Ihm, meiner Schwester und meinem Schwager einfach eine Freude bereiten.<br/>
                        </p>
                    </div>
                    <div className='text-container text-left'>
                        <p>
                            Das war der Zeitpunkt, an dem mir bewusst wurde, wie sehr ich das Zeichnen vermisst hatte. Ich fasste den Entschluss, mir endlich wieder Zeit für meine Kunst zu nehmen.<br />
                        </p>

                        <p className='mb-3'>
                            Für mich bedeutet das:
                        </p>
                        <ul>
                            <li className='lead text-tint'>Wieder tun, was mich glücklich macht.</li>
                            <li className='lead text-tint'>Mehr Zeit für das, was mich ausgleicht.</li>
                            <li className='lead text-tint'>Anderen eine Freude machen können.</li>
                        </ul>
                        <p>
                            Jetzt wünsche ich dir viel Spaß beim Stöbern!
                        </p>
                    </div>
                </Jumbotron>
            </div>
        )
    }
}

export default AboutMe
