import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import withStyles from 'react-jss'
import NavigationBar from './components/NavigationBar'
import LandingPage from './pages/LandingPage'
import Album from './pages/Album'
import AboutMe from './pages/AboutMe'
import Imprint from './pages/Imprint'

const styles = {
  root: {
  },
}

class App extends Component {
  render() {
    const { classes } = this.props

    const landingPage = () => (<LandingPage />)
    const album = () => (<Album />)
    const aboutMe = () => (<AboutMe />)
    const imprint = () => (<Imprint />)
    return (
      <Router>
        <div>
          <NavigationBar />
          <main className={classes.root} role='main' >
            <Route exact path='/' render={landingPage} />
            <Route exact path='/home' render={landingPage} />
            <Route exact path='/about' render={aboutMe} />
            <Route exact path='/impressum' render={imprint} />
            <Route exact path='/datenschutzerklaerung' render={imprint} />
            {/* TODO: Infer album-routes automatically */}
            <Route exact path='/zeichnungen' render={album} />
            <Route exact path='/aquarelle' render={album} />
            <Route exact path='/acryl' render={album} />
            <Route exact path='/digital' render={album} />
            {/* <Redirect to='/' /> */}
          </main>
        </div>
      </Router>
    )
  }
}

export default withStyles(styles)(App)
