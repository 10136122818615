import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

// Bootstrap
import 'bootstrap/dist/css/bootstrap.css'

// Holder-JS (used for Placeholder-images)
import 'holderjs'

// Font-Awesome
import '@fortawesome/fontawesome-free/css/all.css'

import './index.scss'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
