import React, { Component, Fragment } from 'react'
import withStyles from 'react-jss'
import { withRouter } from 'react-router-dom'
import Button from 'react-bootstrap/Button'

import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'

import './Album.scss'
import { LinkContainer } from 'react-router-bootstrap'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import axios from 'axios'
import { appendSuffixToPath, gallerySuffix, thumbnailSuffix, albumSuffix } from '../helpers/ImageVariantsHelper'
import moment from 'moment'
import 'moment/locale/de'
import findIndex from 'lodash/findIndex'
import { getStandardizedDate } from '../helpers/DateHelper'

const styles = {
    jumbotron: {
        paddingBottom: '5.125rem',
    }
}

class Album extends Component {
    state = {
        openGallery: false,
        currentPhotoSlug: null,
        content: {
            title: '',
            images: []
        },
        albumsArray: [],
    }

    componentDidMount() {
        axios.get('/api/albums/index')
            .then(response => {
                axios.get(`/api/albums/${this.getAlbumId()}`)
                    .then(response => {
                        this.setState({ content: response.data })
                    })
                    .catch(error => {
                        // handle error
                        console.log(error)
                    })
                    .then(() => {
                        // always executed
                    })

                this.setState({ albumsArray: response.data.albums })
            })
            .catch(error => {
                // handle error
                console.log(error)
            })
            .then(() => {
                // always executed
            })
    }

    getAlbumId = () => {
        const { match } = this.props
        return match.path.split('/')[1]
    }

    getTitleForRoute = () => {
        const { albumsArray } = this.state
        return albumsArray.length > 0 ?
            albumsArray.filter(entry => entry.id === this.getAlbumId())[0].title : '…lade'
    }

    closeGallery = () => {
        this.setState({
            openGallery: false,
            currentPhotoSlug: null,
        })
    }

    onPhotoPress = (slug) => {
        this.setState({
            openGallery: true,
            currentPhotoSlug: slug,
        })
    }

    toggleGallery = () => {
        this.setState(prevState => ({
            openGallery: !prevState.galleryOpened,
        }))
    }

    getAbsoluteTimeString = (postDateInput) => {
        const postDate = getStandardizedDate(postDateInput)
        moment.locale('de')

        return moment(postDate).format('DD.MM.YYYY')
    }

    getRelativeTimeString = (postDateInput) => {
        const postDate = getStandardizedDate(postDateInput)
        moment.locale('de')

        if (moment().diff(moment(postDate), 'months') < 12) {
            // recent work
            return moment(postDate).format('MMMM YYYY')
            // return moment(postDate).fromNow()   // relative date, e.g. 'vor 2 Monaten'
        } else {
            // work is not recent (older than a year)
            return moment(postDate).format('YYYY')
        }
    }

    getCurrentIndex = () => {
        if (!this.state.currentPhotoSlug) {
            return 0
        } else {
            // return position of currentPhotoSlug in content.images-array
            return findIndex(this.state.content.images, ['slug', this.state.currentPhotoSlug])
        }
    }

    render() {
        const {
            openGallery,
            content
        } = this.state
        const { classes } = this.props

        return (
            <Fragment>
                <div className='album bg-white'>
                    <Jumbotron className={`${classes.jumbotron} pt-5 mb-5`}>
                        <h1 className='text-tint'>{this.getTitleForRoute()}</h1>
                        <p>{String(content.intro).split('\n').map((input) => (
                            <React.Fragment key={input}>{input}<br /></React.Fragment>
                        ))}</p>
                    </Jumbotron>

                    <Container>
                        <Gallery
                            targetRowHeight={400}
                            photos={
                                content.images ?
                                    content.images.map(imageItem => ({
                                        src: appendSuffixToPath(imageItem.src, albumSuffix),
                                        width: imageItem.width,
                                        height: imageItem.height,
                                        imageItem: imageItem
                                    })) : []
                            }
                            renderImage={(renderImageProps) => {
                                const { margin } = renderImageProps
                                const { src, width, height, alt, key = src, imageItem } = renderImageProps.photo
                                const { slug, cardTitle, postDate } = imageItem
                                return (
                                    <figure key={key} onClick={() => { this.onPhotoPress(slug) }}
                                        style={{ margin: margin, cursor: 'pointer', overflow: 'hidden', position: 'relative' }}>
                                        <img width={width} height={height}
                                            alt={alt} src={src}
                                            style={{
                                                display: 'block',
                                                // Note the aspect-ratio...
                                                maxWidth: `calc((100vh - 100px) * (${imageItem.width}/${imageItem.height}))`
                                            }} />
                                        <div><div className='interaction-bar'>
                                            <figcaption className='m-3'>
                                                <div className='card-title h5'>{cardTitle}</div>
                                                <div className={'text-muted w-100'} title={this.getAbsoluteTimeString(postDate)}>
                                                    <i className='far fa-clock' /> {this.getRelativeTimeString(postDate)}
                                                </div>
                                            </figcaption>
                                        </div></div>
                                    </figure>
                                )
                            }} />
                    </Container>

                    {/* Following code is old implementation, which was using the bootstrap-cards and was replaced with above
                        'Gallery'-component courtesy of 'react-photo-gallery'-dependency.
                    */}
                    {/* <Container>
                        <Row>
                            {
                                content.images ?
                                    content.images.map(imageItem => (
                                        <Col key={imageItem.src} md={4} className='mb-4'>
                                            <AlbumItem
                                                cardImageSrc={imageItem.src}
                                                cardTitle={imageItem.cardTitle}
                                                cardDescription={imageItem.cardDescription}
                                                postDate={imageItem.postDate}
                                                onPress={this.onPhotoPress}
                                            />
                                        </Col>
                                    )) : <React.Fragment />
                            }
                        </Row>
                    </Container> */}

                    <LinkContainer to='/'><Button>Back home</Button></LinkContainer>
                </div>

                <ModalGateway>
                    {openGallery ? (
                        <Modal onClose={this.closeGallery}>
                            <Carousel currentIndex={this.getCurrentIndex()} views={content.images.map(imageItem => ({
                                caption: `${imageItem.cardTitle}${
                                    imageItem.cardDescription ? ' // ' + imageItem.cardDescription : ''
                                    }`,
                                source: {
                                    download: imageItem.src,
                                    fullscreen: appendSuffixToPath(imageItem.src, gallerySuffix),
                                    regular: appendSuffixToPath(imageItem.src, gallerySuffix),
                                    thumbnail: appendSuffixToPath(imageItem.src, thumbnailSuffix)
                                }
                            }))} />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </Fragment>
        )
    }
}

export default withRouter(withStyles(styles)(Album))
