import React, { Component, Fragment } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import withStyles from 'react-jss'
import Carousel from 'react-bootstrap/Carousel'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Jumbotron from 'react-bootstrap/Jumbotron'
import axios from 'axios'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'
import find from 'lodash/find'
import ReactImagesCarousel, { Modal, ModalGateway } from 'react-images'

import './LandingPage.scss'
import LatestWork from '../components/LatestWork'
import { appendSuffixToPath, gallerySuffix, thumbnailSuffix, albumSuffix, heroSuffix } from '../helpers/ImageVariantsHelper'

const styles = {
}

class LandingPage extends Component {
    constructor(props) {
        super(props)
        this.carouselRef = React.createRef()
    }

    state = {
        openGallery: false,
        currentPhotoSlug: null,
        albumContents: [
            /* After componentDidMount()-finished successfully, this will look like:
                {
                    "id": "aquarelle",
                    "title": "Aquarelle",
                    "images": [
                        {
                            "cardTitle": "18.09.2007_Cindy2",
                            "cardDescription": "Hier habe ich [] gemalt.",
                            "postDate": "2020-01-05",
                            "src": "content/pages/albums/Aquarelle/18.09.2007_Cindy2.jpg"
                        }
                    ]
                },
                {
                    "id": "zeichnungen",
                    "title": "Zeichnungen",
                    "images": []
                }
            */
        ]
    }

    closeGallery = () => {
        this.setState({
            openGallery: false,
            currentPhotoSlug: null,
        })
    }

    onPhotoPress = (slug) => {
        this.setState({
            openGallery: true,
            currentPhotoSlug: slug,
        })
    }

    toggleGallery = () => {
        this.setState(prevState => ({
            openGallery: !prevState.galleryOpened,
        }))
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)

        // retrieve all album content and move into `state`-variable: `state.content`
        axios.get('/api/albums/index')
            .then(response => {
                const albums = response.data.albums
                albums.forEach(album => {
                    axios.get(`/api/albums/${album.id}`)
                        .then(response => {
                            this.setState(prevState => ({
                                albumContents: prevState.albumContents.concat(response.data),
                            }))
                        })
                        .catch(error => {
                            // handle error
                            console.log(error)
                        })
                        .then(() => {
                            // always executed
                        })
                })
            })
            .catch(error => {
                // handle error
                console.log(error)
            })
            .then(() => {
                // always executed
            })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    getLatestWorkEntry = () => {
        const photos = this.state.albumContents.flatMap(albumContent => albumContent.images)
        const photosSortedByDateDesc = reverse(sortBy(photos, ['postDate']))
        
        return photosSortedByDateDesc.length > 0 ? photosSortedByDateDesc[0] : null
    }

    getLatestWorkInAlbum = (albumId) => {
        const photos = (find(this.state.albumContents, ['id', albumId]) || []).images
        const photosSortedByDateDesc = reverse(sortBy(photos, ['postDate']))
        return photosSortedByDateDesc.length > 0 ? photosSortedByDateDesc[0] : null
    }

    renderLatestWork = () => {
        const latestWorkAlbumItemData = this.getLatestWorkEntry()
        return (
            latestWorkAlbumItemData ?
                <LatestWork className='py-3' albumItemData={latestWorkAlbumItemData} onPhotoPress={() => { this.onPhotoPress() }} /> :
                <Fragment />
        )
    }

    renderGallery = () => {
        const {
            openGallery
        } = this.state
        const latestWorkEntry = this.getLatestWorkEntry()

        return (
            latestWorkEntry ?
                <ModalGateway>
                    {openGallery ? (
                        <Modal onClose={this.closeGallery}>
                            <ReactImagesCarousel views={[
                                {
                                    caption: `${latestWorkEntry.cardTitle}${
                                        latestWorkEntry.cardDescription ? ' // ' + latestWorkEntry.cardDescription : ''
                                        }`,
                                    source: {
                                        download: latestWorkEntry.src,
                                        fullscreen: appendSuffixToPath(latestWorkEntry.src, gallerySuffix),
                                        regular: appendSuffixToPath(latestWorkEntry.src, gallerySuffix),
                                        thumbnail: appendSuffixToPath(latestWorkEntry.src, thumbnailSuffix)
                                    }
                                }
                            ]} />
                        </Modal>
                    ) : null}
                </ModalGateway> : <React.Fragment />
        )
    }

    // Use this for installing parallax effect…
    // handleScroll = (event) => {
    //     const element = this.carouselRef.current
    //     element.styles = { top: '0' }
    // }

    render() {
        return (
            <React.Fragment>
                <div className="landing-page">
                    <section className='landing-page-hero' ref={this.carouselRef}>
                        <Carousel>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={appendSuffixToPath('content/pages/landing-page/DSC03944.jpg', heroSuffix)}
                                    {/* Outcomment this to enable lookup of latest artwork.
                                 src={
                                    this.getLatestWorkInAlbum('aquarelle') ?
                                        this.getLatestWorkInAlbum('aquarelle').src
                                        : ''
                                } */...{}}
                                    {/* Outcomment this to insert placeholder-image.
                                 data-src="holder.js/1440x512?text=Second slide&bg=282c34"
                                  */...{}}
                                    alt="First slide"
                                />
                                <Carousel.Caption>
                                    <h1>Aquarelle</h1>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={appendSuffixToPath('content/pages/landing-page/IMG_1110.JPG', heroSuffix)}
                                    alt="Second slide"
                                />
                                <Carousel.Caption>
                                    <h1>Zeichnungen</h1>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={appendSuffixToPath('content/pages/landing-page/DSC03939.jpg', heroSuffix)}
                                    alt="Third slide"
                                />
                                <Carousel.Caption>
                                    <h1>Acryl</h1>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </section>
                    <section className='landing-page-content bg-white'>
                        <Jumbotron className='bg-white'>
                            <h1>Hallo und schön, dass du da bist!</h1>
                            <p className='text-muted'>
                                Ich bin Kira und ich freue mich, dir meine Arbeiten auf Zeichenzauber zu präsentieren.
                            </p>
                            <p>
                                <LinkContainer to='/about'>
                                    <Button variant="primary">Mehr über mich</Button>
                                </LinkContainer>
                            </p>
                        </Jumbotron>

                        {
                            this.renderLatestWork()
                        }

                        {/* Marketing messaging and featurettes */}
                        {/* Wrap the rest of the page in another container to center all the content. */}
                        <Container className="marketing">
                            {/* Three columns of text below the carousel */}
                            <Row>
                                <Col lg={4}>
                                    <img
                                        className="rounded-circle"
                                        src={
                                            this.getLatestWorkInAlbum('aquarelle') ?
                                                appendSuffixToPath(this.getLatestWorkInAlbum('aquarelle').src, albumSuffix)
                                                : ''
                                        }
                                        {/* Outcomment this to enable lookup of latest artwork.
                                        data-src="holder.js/140x140?text=Second slide&fg=777&bg=777" */...{}}
                                        alt=""
                                    />
                                    <h2>Aquarelle</h2>
                                    <p>Wasser marsch!</p>
                                    <p><LinkContainer to='/aquarelle'><Button variant="primary">Album betrachten &raquo;</Button></LinkContainer></p>
                                </Col>
                                <Col lg={4}>
                                    <img
                                        className="rounded-circle"
                                        src={
                                            this.getLatestWorkInAlbum('zeichnungen') ?
                                                appendSuffixToPath(this.getLatestWorkInAlbum('zeichnungen').src, albumSuffix)
                                                : ''
                                        }
                                        alt=""
                                    />
                                    <h2>Zeichnungen</h2>
                                    <p>Lust auf Präzision</p>
                                    <p><LinkContainer to='/zeichnungen'><Button variant="primary">Album betrachten &raquo;</Button></LinkContainer></p>
                                </Col>
                                <Col lg={4}>
                                    <img
                                        className="rounded-circle"
                                        src={
                                            this.getLatestWorkInAlbum('acryl') ?
                                                appendSuffixToPath(this.getLatestWorkInAlbum('acryl').src, albumSuffix)
                                                : ''
                                        }
                                        alt=""
                                    />
                                    <h2>Acryl</h2>
                                    <p>Mut zur Farbe</p>
                                    <p><LinkContainer to='/acryl'><Button variant="primary">Album betrachten &raquo;</Button></LinkContainer></p>
                                </Col>
                            </Row>
                            <hr className="featurette-divider" />
                            <Row className="featurette">
                                <Col md={7}>
                                    <h2 className="featurette-heading">First featurette heading. <span className="text-muted">It’ll blow your mind.</span></h2>
                                    <p className="lead">Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper. Praesent commodo cursus magna, vel scelerisque nisl consectetur. Fusce dapibus, tellus ac cursus commodo.</p>
                                </Col>
                                <Col md={5}>
                                    <img
                                        className="bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                                        data-src="holder.js/500x500&fg=aaa&bg=eee"
                                        alt=""
                                    />
                                </Col>
                            </Row>
                            <hr className="featurette-divider" />
                            <Row className="featurette">
                                <Col md={{ span: 7, order: 2 }}>
                                    <h2 className="featurette-heading">Oh yeah, it’s that good. <span className="text-muted">See for yourself.</span></h2>
                                    <p className="lead">Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper. Praesent commodo cursus magna, vel scelerisque nisl consectetur. Fusce dapibus, tellus ac cursus commodo.</p>
                                </Col>
                                <Col md={{ span: 5, order: 1 }}>
                                    <img
                                        className="bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                                        data-src="holder.js/500x500&fg=aaa&bg=eee"
                                        alt=""
                                    />
                                </Col>
                            </Row>
                            <hr className="featurette-divider" />
                            <Row className="featurette">
                                <Col md={7}>
                                    <h2 className="featurette-heading">And lastly, this one. <span className="text-muted">Checkmate.</span></h2>
                                    <p className="lead">Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper. Praesent commodo cursus magna, vel scelerisque nisl consectetur. Fusce dapibus, tellus ac cursus commodo.</p>
                                </Col>
                                <Col md={5}>
                                    <img
                                        className="bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                                        data-src="holder.js/500x500&fg=aaa&bg=eee"
                                        alt=""
                                    />
                                </Col>
                            </Row>
                            <hr className="featurette-divider" />
                        </Container>
                    </section>
                </div>
                {this.renderGallery()}
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(LandingPage)
