import React, { Component } from 'react'
import withStyles from 'react-jss'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { IndexLinkContainer, LinkContainer } from 'react-router-bootstrap'
import logo from '../assets/logo-header.svg'

import './NavigationBar.scss'

const styles = {
}

const onSelectDoNothing = () => {}

class NavigationBar extends Component {
    render() {
        // const { classes } = this.props
        return (
            <Navbar expand='lg' sticky='top' collapseOnSelect={true}>
                <IndexLinkContainer to='/'>
                    <Navbar.Brand><img height={40} src={logo} alt='brand-logo' /></Navbar.Brand>
                </IndexLinkContainer>
                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                <Navbar.Collapse id='basic-navbar-nav'>
                    {/* activeKey='', to disable Nav-Component's bevahviour to automatically set 'active'-class
                        on children. Children are inferring their active-style themselves. */}
                    <Nav className='mr-auto' activeKey='' onSelect={onSelectDoNothing}>
                        <IndexLinkContainer to='/'>
                            <Nav.Link>Home</Nav.Link>
                        </IndexLinkContainer>
                        <NavDropdown title='Zeichenstile' id='basic-nav-dropdown'>
                            <LinkContainer to='/aquarelle'>
                                <NavDropdown.Item>Aquarelle</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to='/zeichnungen'>
                                <NavDropdown.Item>Zeichnungen</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to='/acryl'>
                                <NavDropdown.Item>Acryl</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to='/digital'>
                                <NavDropdown.Item>Digital</NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>
                        <NavDropdown title='Über' id='basic-nav-dropdown'>
                            <NavDropdown.Item href='/about'>Über mich</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href='/impressum'>Impressum</NavDropdown.Item>
                        </NavDropdown>
                        <IndexLinkContainer to='#contact'>
                            <Nav.Link>Kontakt</Nav.Link>
                        </IndexLinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default withStyles(styles)(NavigationBar)
