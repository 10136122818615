import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles from 'react-jss'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import moment from 'moment'
import 'moment/locale/de'
import { getStandardizedDate } from '../helpers/DateHelper'
import { appendSuffixToPath, albumSuffix } from '../helpers/ImageVariantsHelper'

const styles = {
    cardRoot: {
        // width: '18rem',
    },
}

class AlbumItem extends Component {
    renderTime = () => {
        const shouldRenderTime = Boolean(this.props.postDate)

        return shouldRenderTime ? (
            <div className={'text-light-muted w-100 mb-3'} title={this.getAbsoluteTimeString()}>
                <i className='far fa-clock' /> {this.getRelativeTimeString()}
            </div>
        ) : <React.Fragment />
    }

    getAbsoluteTimeString = () => {
        const postDate = getStandardizedDate(this.props.postDate)
        moment.locale('de')

        return moment(postDate).format('DD.MM.YYYY')
    }

    getRelativeTimeString = () => {
        const postDate = getStandardizedDate(this.props.postDate)
        moment.locale('de')

        if (moment().diff(moment(postDate), 'months') < 12) {
            // recent work
            return moment(postDate).format('MMMM YYYY')
            // return moment(postDate).fromNow()   // relative date, e.g. 'vor 2 Monaten'
        } else {
            // work is not recent (older than a year)
            return moment(postDate).format('YYYY')
        }
    }

    render() {
        const { className, classes, slug, cardImageSrc, cardTitle, cardDescription } = this.props
        const srcAlbumVariant = appendSuffixToPath(cardImageSrc, albumSuffix)

        return (
            <Card className={classNames(classes.cardRoot, 'shadow', className)} bg="dark" text="light">
                <Card.Img variant="top" src={srcAlbumVariant} />
                <Card.Body>
                    <Card.Title>{cardTitle}</Card.Title>
                    {this.renderTime()}
                    <Card.Text>{cardDescription}</Card.Text>
                    <Button
                        variant="secondary"
                        onClick={() => { this.props.onPress(slug) }}
                    >in Galerie betrachten</Button>
                </Card.Body>
            </Card>
        )
    }
}

AlbumItem.propTypes = {
    className: PropTypes.string,
    cardImageSrc: PropTypes.string,
    cardTitle: PropTypes.string,
    cardDescription: PropTypes.string,
    postDate: PropTypes.string,
    onPress: PropTypes.func,
    slug: PropTypes.string,
}

export default withStyles(styles)(AlbumItem)
