import React, { Component } from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron'

import './Imprint.scss'

class Imprint extends Component {
    render() {
        return (
            <div className='imprint'>
                <Jumbotron className='mb-0'>
                    <h1 className='text-tint'>Impressum</h1>
                    <p>
                        Kira Huber
                        Bliblablub…
                    </p>
                </Jumbotron>
            </div>
        )
    }
}

export default Imprint
