/// Dates in data are sometimes abbreviated in a format like '2019' e.g. To fix warnings with Moment-js,
/// we will reformat these dates to correct standardized format. Example: '2019' -> '2019-01-01'
const getStandardizedDate = (dateString) => {
    const regex = /\d\d\d\d/    // matches four digits, like '2019'
    const match = regex.exec(dateString)
    
    if (match.length > 0 && match[0].length === dateString.length) {
        return `${dateString}-01-01`
    } else {
        return dateString
    }
}

export { getStandardizedDate }
