// normally gallery-variant should be '_a'. For performance reasons, we are changing this to '_b'…
// const gallerySuffix = '_a'
const gallerySuffix = '_b'
const albumSuffix = '_b'
const thumbnailSuffix = '_z'

const heroSuffix = '_hero'

const appendSuffixToPath = (path, suffix) => {
    const regex = /(.*?)(\.[^.]+$|$)/    // second group matches file-ending, first matches everything up to file-ending
    const result = `$1${suffix}$2`
    return path.replace(regex, result)
}

export { 
    appendSuffixToPath,
    gallerySuffix,
    albumSuffix,
    thumbnailSuffix,
    heroSuffix,
}
